import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './App';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import "bootstrap/dist/css/bootstrap.min.css";
import './assets/scss/main.scss';
import "./assets/scss/normalize.scss";

import { store } from './store/config-store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

