import Routers from "./routers/Routers";

export const App = () => {

  return (
    <>
      <Routers />
    </>
  );
}


