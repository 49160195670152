import "./intro.scss"

export const Intro = () => {
  return (
    <div className="intro">
      <h2 className="intro__title">Frontend Mentor</h2>

      <p className="intro__text">Feedback Board</p>
    </div>
  )
}