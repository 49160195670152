export const filterBtn = [
  {
    id: 1,
    type: "All",
    classs: "b-active",
    status: true
  },
  {
    id: 2,
    type: "UI",
    classs: "",
    status: false
  },
  {
    id: 3,
    type: "UX",
    classs: "",
    status: false

  },
  {
    id: 4,
    type: "Enhancement",
    classs: "",
    status: false

  },
  {
    id: 5,
    type: "Bug",
    classs: "",
    status: false

  },

  {
    id: 6,
    type: "Feature",
    classs: "",
    status: false

  },

]